import React from "react";
import { motion } from "framer-motion";

const PersonalAssistant = () => {
  const particles = Array.from({ length: 15 });

  const particleVariants = {
    initial: {
      y: "100vh",
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      y: ["100vh", "-10vh"],
      opacity: [0.8, 0.5, 0.8],
      scale: [0.5, 1.5, 0.8],
      transition: {
        duration: 8,
        ease: "easeInOut",
        repeat: Infinity,
        repeatType: "reverse",
        delay: Math.random() * 2,
      },
    },
  };

  const textVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.5,
      },
    },
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        background: "linear-gradient(120deg, #6a11cb, #2575fc)",
        color: "#fff",
        fontFamily: "Arial, sans-serif",
        width: "100%",
      }}
    >
      {particles.map((_, index) => (
        <motion.div
          key={index}
          variants={particleVariants}
          initial="initial"
          animate="animate"
          style={{
            position: "absolute",
            width: `${Math.random() * 50 + 20}px`,
            height: `${Math.random() * 50 + 20}px`,
            background: "rgba(255, 255, 255, 0.7)",
            borderRadius: "50%",
            boxShadow: "0 0 10px rgba(255, 255, 255, 0.7)",
          }}
        />
      ))}

      <motion.div
        initial="hidden"
        animate="visible"
        variants={textVariants}
        style={{
          zIndex: 10,
          padding: "20px",
          fontSize: "1rem",
          background: "rgba(0, 0, 0, 0.6)",
          borderRadius: "15px",
          width: "80%",
        }}
      >
        <h1>Welcome to Your Personal Assistant!</h1>
        <p>
          This feature is coming soon. Stay tuned for an amazing experience that
          makes your life easier and more organized!
        </p>
      </motion.div>
    </div>
  );
};

export default PersonalAssistant;
