import axios from "axios";

const url =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_URL
    : process.env.REACT_APP_PROD_URL;
const AxiosInstance = axios.create({
  baseURL: url,
});

AxiosInstance.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem("token");
    if (jwtToken) {
      config.headers["Authorization"] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default AxiosInstance;
