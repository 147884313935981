import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import "./ticket.css";

const TicketList = ({ userRole }) => {
  const [tickets, setTickets] = useState([
    {
      _id: "1",
      title: "Login Issue",
      description: "Unable to login",
      status: "open",
      createdAt: "2024-12-30T10:00:00Z",
    },
    {
      _id: "2",
      title: "Bug in Dashboard",
      description: "Data not updating",
      status: "pending",
      createdAt: "2024-12-31T08:00:00Z",
    },
  ]);
  const [ticket, setTicket] = useState({
    title: "",
    description: "",
  });

  return (
    <div className="ticket-app-container">
      <div className="background-container">
        <div className="ticket-form">
          <h2>Create New Ticket</h2>
          <input
            type="text"
            placeholder="Title"
            value={ticket.title}
            onChange={(e) => setTicket({ ...ticket, title: e.target.value })}
          />
          <textarea
            placeholder="Description"
            value={ticket.description}
            onChange={(e) =>
              setTicket({ ...ticket, description: e.target.value })
            }
          />
          <button className="btn-submit">Submit Ticket</button>
        </div>
        <div className="tickets">
          {tickets.map((item) => (
            <div className="ticket" key={item._id}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
              <span className={`status ${item.status}`}>{item.status}</span>
              <small>{new Date(item.createdAt).toLocaleString()}</small>
              {userRole === "admin" && (
                <div className="actions">
                  <button>Update</button>
                  <button>Resolve</button>
                  <button>Delete</button>
                </div>
              )}
            </div>
          ))}
        </div>
        {[...Array(15)].map((_, index) => (
          <motion.div
            className="particle"
            key={index}
            initial={{
              opacity: 0,
              y: Math.random() * 100,
              x: Math.random() * window.innerWidth,
            }}
            animate={{
              opacity: [0.2, 1, 0.2],
              y: [Math.random() * -500, Math.random() * 500],
              x: [
                Math.random() * window.innerWidth,
                Math.random() * window.innerWidth,
              ],
            }}
            transition={{
              duration: Math.random() * 10 + 5,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          ></motion.div>
        ))}
      </div>
    </div>
  );
};

export default TicketList;
