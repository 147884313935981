// import React from "react";
// import { motion } from "framer-motion";
// export const Home = () => {
//   return (
//     <>
//       <main>
//         <motion.div
//           initial={{ scale: 0.8, opacity: 0 }}
//           animate={{ scale: 1, opacity: 1 }}
//           transition={{ duration: 0.8 }}
//           className="main-content"
//         >
//           <h1 className="welocme-text">Welcome to 🎲 Casino</h1>
//           {/* <p>
//             Discover thrilling casino games with big rewards! Spin the reels,
//             roll the dice, and feel the excitement from anywhere. Join now and
//             claim your welcome bonus!
//           </p> */}
//           <ul className="bullet-sec">
//             <li>
//               Your past does not define your future. You can always rewrite the
//               story.
//             </li>
//             <li>
//               Recovery is not for people who need it, it’s for people who want
//               it.
//             </li>
//             <li>
//               You can’t change what’s going on around you until you start
//               changing what’s going on within you.
//             </li>
//           </ul>
//         </motion.div>
//       </main>
//     </>
//   );
// };

import React from "react";
import { motion } from "framer-motion";
import "./home.css";
export const Home = () => {
  const gradientAnimation = {
    animate: {
      backgroundPosition: ["0% 50%", "100% 50%"],
      transition: { duration: 10, repeat: Infinity, ease: "linear" },
    },
  };

  const cardVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 1.5, delay: 0.5 },
    },
  };

  return (
    <motion.div
      animate="animate"
      variants={gradientAnimation}
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        overflow: "hidden",
        // background:
        //   "linear-gradient(120deg, rgba(30, 60, 114, 0.8), rgba(42, 82, 152, 0.8))",
        backgroundSize: "200% 200%",
      }}
    >
      {/* Background Cards */}
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: -1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {[...Array(4)].map((_, i) => (
          <motion.div
            key={i}
            initial={{ opacity: 0.3, scale: 0.8 }}
            animate={{
              opacity: [0.3, 0.8],
              scale: [0.8, 1],
              rotate: [0, 360],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              delay: i * 1.5,
            }}
            // style={{
            //   width: "150px",
            //   height: "200px",
            //   // background:
            //   //   "linear-gradient(145deg, rgba(255, 255, 255, 0.2), rgba(255, 117, 140, 0.2))",
            //   background: "blue",
            //   backdropFilter: "blur(10px)",
            //   borderRadius: "20px",
            //   margin: "0 15px",
            //   boxShadow: "0 4px 30px rgba(0, 0, 0, 0.2)",
            // }}
            className="bg-transparent-card"
          />
        ))}
      </div>

      {/* Content Section */}
      <motion.div
        initial="hidden"
        animate="visible"
        variants={cardVariants}
        style={{
          textAlign: "center",
          color: "white",
          zIndex: 1,
        }}
      >
        <h1 className="welcome-text">Welcome to 🎲 CasinoRehab</h1>
        <p className="main-content">
          Your past does not define your future. You can always rewrite the
          story. Recovery is not for people who need it, it's for people who
          want it. Start changing what's going on within you!
        </p>
      </motion.div>
    </motion.div>
  );
};
